<template>
  <component
    :is="tag"
    :href="link && tag === 'a' ? link : ''"
    :target="link && tag === 'a' && openOtherTab ? '_blank' : ''"
    :class="[
      b.wrap,
      b[type],
      b[shape],
      b[size],
      b[color],
      b[width],
      disabled ? b.disabled : '']">
    <slot/>
  </component>
</template>

<script>
export default {
  name: 'basic-btn',
  props: {
    type: {
      type: String,
      default: 'bg',
      validator: (value) => ['bg', 'bdr'].includes(value),
    },
    shape: {
      type: String,
      default: 'arc',
      validator: (value) => ['arc', 'straight'].includes(value),
    },
    size: {
      type: String,
      default: 'lg',
      validator: (value) => ['lg', 'sm'].includes(value),
    },
    width: {
      type: String,
      default: 'w-default',
      validator: (value) => ['w-default', 'fit-content', 'full'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'button',
      validator: (value) => ['button', 'a'].includes(value),
    },
    link: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'gry',
      validator: (value) => ['blk', 'wht', 'gry'].includes(value),
    },
    openOtherTab: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module="b">
.wrap {
  display: inline-block;
  padding: 11px 24px;
  transition: all .3s;
  cursor: pointer;
  font-size: 16px;
  border-radius: 40px;
  font-weight: 700;
  border: 1px solid var(--label-primary);
  text-align: center;

  &.fit-content {
    width: fit-content;
  }

  &.full {
    width: 100%;
    display: block;
    text-align: center;
  }

  &.w-default {
    min-width: 280px;
  }

  &.bg {
    background-color: var(--label-primary);
    color: #ffffff;
    &.wht {
      background-color: #ffffff;
      color: var(--brand-gray);
      border-color: #ffffff;
    }
    &.gry {
      background-color: var(--brand-gray);
      border-color: var(--brand-gray);
      color: #ffffff;
    }
    &.semi-transparent {
      background-color: var(--label-disabled);
      border-color: transparent;
    }
  }

  &.bdr {
    border: 1px solid var(--label-primary);
    background-color: transparent;
    &.wht {
      border-color: var(--label-inverse-primary);
      color: var(--label-inverse-primary);
    }
    &.wht-blk {
      border-color: var(--label-inverse-primary);
      color: var(--label-inverse-primary);
      background-color: rgba(#000000, 0.2);
    }
    &.gry {
      border-color: var(--brand-gray);
      background-color: transparent;
      color: var(--brand-gray);
    }
  }

  &:hover {
    opacity: .4;
  }

  &.straight {
    border-radius: 0;
  }

  &.sm {
    font-size: 12px;
    padding: 6px 16px 7px;
  }

  &.disabled {
    opacity: .4;
    cursor: default;
  }
}
</style>
