<template>
  <div :class="s.wrap">
    <slot/>
  </div>
</template>

<style lang="scss" module="s">
.wrap {
  padding: 40px 64px;
  border-radius: 48px;
  background-color: #ffffff;
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.16);

  @include smView {
    padding: 32px;
    border-radius: 28px;
  }
}
</style>
