<template>
  <div :class="s.wrap" v-if="propData" :style="style">
    <div
      :class="s.img"
      :style="`background-image: url(${propData.medias.length ? propData.medias[0].url : '/images/sample.png'});`">
      <div :class="s.img_label">{{ propData.propTypes[0].name }}</div>
    </div>
    <div>
      <div :class="[s.label_wrap, showLikeIcon ? s.label_flex : '']">
        <div :class="s.label">{{ propData.label }}</div>
        <div v-if="showLikeIcon" :class="s.star" @click="changeLikeStatus">
          <div
            :class="s.icon"
            :style="`background-image: url(/images/front_icons/star${isLiked ? '_active' : ''}.svg)`"/>
        </div>
      </div>
      <ul :class="s.additionals">
        <li v-for="item in adjustedItems" :key="item.name">
          <!-- ラベル -->
          <div>
            <span v-if="item.name === 'price'">{{ priceLabel }}</span>
            <span v-else>{{ item.label }}</span>
          </div>
          <div :class="s.separator"/>
          <div>
            <span v-if="item.name === 'address'">{{ address }}</span>
            <span v-else-if="item.name === 'price' && propData.price && !propData.is_negotiated">&yen;{{ propData[item.name].toLocaleString() }}</span>
            <span v-else-if="item.name === 'price' && (!propData.price || propData.is_negotiated)">応相談</span>
            <span v-else-if="item.name === 'building_area'">{{ buildingArea }}</span>
            <span v-else-if="item.name === 'land_area'">{{ landArea }}</span>
            <span v-else-if="item.name === 'estate' && propData.estates.length">{{ propData.estates[0].label }}</span>
            <span v-else-if="item.name === 'tel' && propData.estates.length">{{ propData.estates[0].tel }}</span>
            <span v-else-if="item.name === 'updated_at'">{{ latestUpdate }}</span>
            <span v-else>{{ propData[item.name] || '-' }}</span>
          </div>
        </li>
      </ul>
      <ul :class="s.likes" v-if="!showLikeIcon">
        <li>
          <div
            :class="s.icon"
            :style="`background-image: url(/images/front_icons/star${isLiked ? '_active' : ''}.svg)`"
            @click="changeLikeStatus"/>
          <div :class="s.num">{{ propData.like_count }}</div>
        </li>
        <li>
          <div :class="s.icon" :style="`background-image: url(/images/front_icons/view.svg)`"/>
          <div :class="s.num">{{ propData.view_count }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'property-card',
  mixins: [cf],
  props: {
    propData: {
      type: Object,
    },
    showItems: {
      type: Array,
      default: () => ['number', 'address', 'price', 'land_area', 'building_area', 'age', 'floor', 'estate', 'tel', 'updated_at'],
    },
    labelSize: {
      type: Number,
      default: 16,
    },
    imgRatio: {
      type: Number,
      default: 100,
    },
    smImgRatio: {
      type: Number,
      default: 50,
    },
    showLikeIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: {
        isLoading: {
          like: false,
        },
      },
      items: [
        {
          name: 'number',
          label: '物件番号',
        },
        {
          name: 'address',
          label: '所在地',
        },
        {
          name: 'price',
        },
        {
          name: 'land_area',
          label: '土地面積',
        },
        {
          name: 'building_area',
          label: '建物面積',
        },
        {
          name: 'landmark',
          label: '地目',
        },
        {
          name: 'current_status',
          label: '現況',
        },
        {
          name: 'station',
          label: '最寄駅',
        },
        {
          name: 'bus_station',
          label: '最寄りバス停',
        },
        {
          name: 'city_planning',
          label: '都市計画',
        },
        {
          name: 'use_area',
          label: '用途地域',
        },
        {
          name: 'building_ratio',
          label: '建ぺい率',
        },
        {
          name: 'floor_ratio',
          label: '容積率',
        },
        {
          name: 'age',
          label: '建築年',
        },
        {
          name: 'structure',
          label: '構造',
        },
        {
          name: 'floor',
          label: '間取り',
        },
        {
          name: 'facility',
          label: '設備',
        },
        {
          name: 'estate',
          label: '担当業者',
        },
        {
          name: 'tel',
          label: '電話番号',
        },
        {
          name: 'updated_at',
          label: '最終更新日',
        },
      ],
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    address() {
      return `${this.propData.city}${this.propData.town}${this.propData.address}${this.propData.address2 || ''}`;
    },
    landArea() {
      if (!this.propData) return '-';
      let json;
      try {
        json = JSON.parse(this.propData.land_area);
      } catch {
        return '-';
      }
      if (!json || !json.meter) return '-';
      return `${json.meter || '-'}㎡`;
    },
    buildingArea() {
      if (!this.propData) return '-';
      let json;
      try {
        json = JSON.parse(this.propData.building_area);
      } catch {
        return '-';
      }
      if (!json) return '-';
      let str = '';
      let count = 0;
      json.forEach((obj, i) => {
        if (Number(obj.meter)) {
          if (count === 0) str += `${i + 1}F：${obj.meter || '-'}㎡`;
          else str += `｜${i + 1}F：${obj.meter || '-'}㎡`;
          count += 1;
        }
      });
      return str || '-';
    },
    latestUpdate() {
      const revision = this.propData.revision || this.propData.revisions?.[0];
      if (!revision) return '-';
      return this.formatTimestamp(revision.created_at, 'YYYY年MM月DD日');
    },
    adjustedItems() {
      const result = this.items.filter((item) => this.showItems.includes(item.name));
      return result;
    },
    isLiked() {
      return this.user.likes.likeIds.includes(this.propData.id);
    },
    priceLabel() {
      const type = this.propData.dealTypes[0];
      if (!type) return '価格';
      return type.is_rent ? '賃料（月額）' : '売却希望価格';
    },
    propertyStatus() {
      return this.helper.master.default.labels.propertyFlags.find((status) => status.value === this.propData.flag);
    },
    style() {
      return {
        '--img-ratio': `${this.imgRatio}%`,
        '--img-ratio-sm': `${this.smImgRatio}%`,
        '--label-size': `${this.labelSize}px`,
        '--status-label-color': `#${this.propertyStatus.color}`,
      };
    },
  },
  methods: {
    async changeLikeStatus(e) {
      e.preventDefault();
      e.stopPropagation();
      this.flag.isLoading.like = true;
      await cf.updateLikes(this.propData.id);
      this.$store.dispatch('user/likes/setLikes');
      this.flag.isLoading.like = false;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  color: var(--label-primary);
}

.img {
  width: 100%;
  padding-top: var(--img-ratio);
  background-size: cover;
  background-position: center;
  border-radius: 28px;
  position: relative;
  overflow: hidden;
  &_label {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 6px 12px 8px 16px;
    background-color: var(--label-primary);
    font-weight: 700;
    font-size: 12px;
    color: var(--label-inverse-primary);
    border-radius: 0 16px 0 0;
  }
}
.label {
  font-size: var(--label-size);
  font-weight: 700;
  &_wrap {
    margin-top: 12px;
  }
  &_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .star {
      padding: 10px;
      border-radius: 50px;
      border: 1px solid var(--border-divider-gray);
      background-color: var(--surface-field-gray);
      .icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.statusFlag {
  &_wrap {
    position: absolute;
    top: 16px;
    left: 0;
  }
  position: relative;
  background-color: var(--status-label-color);
  padding: 4px 12px;
  font-weight: 700;
  color: var(--label-inverse-primary);
  &::after, &::before {
    content: '';
    border-width: 17px;
    border-style: solid;
    position: absolute;
    right: 0;
    transform: translateX(50%);
  }
  &::after {
    top: 0;
    border-color: var(--status-label-color) transparent transparent transparent;
  }
  &::before {
    bottom: 0;
    border-color: transparent transparent var(--status-label-color) transparent;
  }
}

.additionals {
  margin-top: 12px;
  > li {
    &:not(:first-child) {
      margin-top: 12px;
    }
    font-size: 12px;
    font-weight: 700;
    opacity: .63;
    display: flex;
    align-items: center;

    .separator {
      margin: 0 8px;
      width: 1px;
      height: 12px;
      transform: rotate(17.3deg);
      opacity: .2;
      background-color: var(--label-primary);
      display: inline-block;
    }
  }
}
.likes {
  display: flex;
  font-size: 12px;
  font-weight: 700;
  margin-top: 12px;
  > li {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
  .num {
    opacity: .63;
  }
}

@include smView {
  .img {
    padding-top: var(--img-ratio-sm);
  }
}
</style>
