<template>
  <div :class="[
    s.wrap,
    checked ? s.checked : '',
  ]">
    <div
      :class="s.icon"
      :style="`background-image: url(/images/front_icons/check${checked ? '_checked' : ''}.svg)`"/>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'check-btn',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  padding: 9px 20px;
  border: 1px solid;
  border: 1px solid rgba(#000000, .2);
  border-radius: 11px;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &.checked {
    font-weight: 700;
    background-color: rgba(#000000, .06);
    border-color: #000000;
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-position: center;
}
</style>
