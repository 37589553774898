<template>
  <div :class="s.wrap">
    <div :class="s.head">
      <div :class="s.title">
        <span v-if="['lg', 'md'].includes(layout.displaySize)">桐生市空き家・空き地バンク</span>
        <span v-else>桐生市空き家<br>空き地バンク</span>
      </div>
      <img :class="s.arc" src="/images/arc_circle.svg" alt="">
    </div>

    <Container :class="s.main">
      <div :class="s.cover_wrap">

        <!-- 検索条件 -->
        <BasicBox :class="s.searchItems_wrap" id="trigger-first">
          <ul :class="s.searchItems">
            <li
              v-for="item in searchItems"
              :key="item.name">
              <div :class="s.label">{{ item.label }}</div>
              <Loader
                :mini="true"
                v-if="flag.isLoading[item.name]"
                :height="50"/>
              <FlexList
                v-if="item.name !== 'categories'"
                :spaceX="16"
                :spaceY="16"
                :count="['lg', 'md'].includes(layout.displaySize) ? item.options.length : null"
                :smCount="item.smCount"
                :wrap="item.wrap">
                <li
                  v-for="opt in item.options"
                  :key="opt.id">
                  <CheckBtn
                    @click="changeOptions(item.name, opt[item.select_label])"
                    :checked="isChecked(item.name, opt[item.select_label])">{{ opt.label || opt.name }}</CheckBtn>
                </li>
              </FlexList>

              <!-- カテゴリのみレイアウトが違うので -->
              <div v-if="item.name === 'categories'" :class="s.categories">
                <ul>
                  <li
                    v-for="ctg in adjstedCategories.even"
                    :key="ctg.id">
                    <CheckBtn
                      @click="changeOptions(item.name, ctg.id)"
                      :checked="isChecked(item.name, ctg.id)">{{ ctg.name }}</CheckBtn>
                  </li>
                </ul>
                <ul>
                  <li
                    v-for="ctg in adjstedCategories.odd"
                    :key="ctg.id">
                    <CheckBtn
                      @click="changeOptions(item.name, ctg.id)"
                      :checked="isChecked(item.name, ctg.id)">{{ ctg.name }}</CheckBtn>
                  </li>
                </ul>
              </div>

            </li>
          </ul>
          <div :class="s.keyword">
            <input
              type="text"
              placeholder="フリーワードで検索"
              v-model="keyword"
              @input="changeSearchOption">
          </div>
        </BasicBox>
        <div :class="s.cover"/>
      </div>

      <!-- スクロール時のみ出現 -->
      <transition name="fade" :class="s.bar">
        <SearchItemBar
          v-if="layout.scrolled.second"
          :options="orderByOptions"
          :initTarget="orderBy"
          :searchOptionLabels="searchItemLabels"
          @changeOrder="changeOrder"
          ref="searchItemBar">{{ total }}件を表示</SearchItemBar>
      </transition>

      <spacer :y="5" id="trigger-second"/>

      <div>
        <div :class="s.note">
          <span v-if="properties.length">{{ total }}件を表示</span>
          <span v-else>条件に合致する物件は見つかりませんでした。</span>
        </div>

        <spacer :y="2"/>

        <div
          class="pc"
          :class="s.order">
          <FlexList :spaceX="16" :align="'center'">
            <li
              v-for="opt in orderByOptions"
              :key="opt.orderBy">
              <basic-btn
                :type="orderBy === opt.orderBy ? 'bg' : 'bdr'"
                :size="'sm'"
                :color="'gry'"
                :width="'fit-content'"
                @click="changeOrder(opt.orderBy)"
                >{{ opt.label }}</basic-btn>
            </li>
          </FlexList>
        </div>

        <div
          class="sp"
          :class="s.options_wrap">
          <div
            :class="s.options_btn"
            @click="flag.showOrderOption = !flag.showOrderOption"><font-awesome-icon :class="s.down" :icon="['fas', 'caret-down']"/>{{ selectedOrderBy }}</div>
          <ul :class="s.options" v-if="flag.showOrderOption">
            <li
              v-for="opt in orderByOptions"
              :key="opt.orderBy"
              @click="changeOrder(opt.orderBy)">{{ opt.label }}</li>
          </ul>
        </div>
        <spacer :y="8"/>

        <!-- 物件一覧 -->
        <FlexList
          :spaceX="23"
          :spaceY="64"
          :smSpaceY="32"
          :count="4"
          :smCount="1"
          :wrap="true"
          :class="s.properties">
          <li v-for="p in properties" :key="p.number">
            <router-link :to="`/bukken/${p.number}/`" @click="addViewCount(p.id)" target="_blank">
              <PropertyCard
                :propData="p"
                :likeIsActive="n % 3 === 0"/>
            </router-link>
          </li>
        </FlexList>
        <Loader v-if="flag.isLoading.properties" />
        <spacer :y="8.5"/>
        <FlexList :align="'center'" v-if="properties.length < total">
          <li><basic-btn :type="'bdr'" @click="showMore">もっと見る</basic-btn></li>
        </FlexList>
      </div>

      <spacer :y="20" :smY="15"/>
      <div :class="s.map_wrap">
        <div v-if="properties.length" :class="s.map_label">マップで検索</div>
        <MapPoints
          v-if="properties.length"
          :properties="mapDropPins"/>
      </div>
    </Container>

    <spacer :y="30" :smY="20"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  Container,
  FlexList,
  PropertyCard,
  BasicBox,
  Spacer,
  CheckBtn,
  BasicBtn,
  SearchItemBar,
  Loader,
  MapPoints,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  mixins: [cf],
  components: {
    Container,
    FlexList,
    PropertyCard,
    BasicBox,
    Spacer,
    CheckBtn,
    BasicBtn,
    SearchItemBar,
    Loader,
    MapPoints,
  },
  data() {
    return {
      flag: {
        showOrderOption: false,
        isLoading: {
          property: false,
          dealTypes: true,
          propTypes: true,
          categories: true,
        },
      },
      searchItems: [
        {
          name: 'dealTypes',
          label: '物件の種類',
          options: [],
          select_label: 'id',
          selected: [],
          smCount: 2,
        },
        {
          name: 'propTypes',
          label: '土地・建物の状態',
          options: [],
          select_label: 'id',
          selected: [],
          wrap: true,
        },
        {
          name: 'areas',
          label: 'エリア',
          options: [
            {
              name: '桐生',
              label: '桐生地区',
            },
            {
              name: '新里',
              label: '新里地区',
            },
            {
              name: '黒保根',
              label: '黒保根地区',
            },
          ],
          select_label: 'name',
          selected: [],
          wrap: true,
        },
        {
          name: 'categories',
          label: 'こだわり条件',
          smScroll: true,
          options: [],
          select_label: 'id',
          selected: [],
        },
      ],
      orderBy: 'number',
      orderByOptions: [
        {
          orderBy: 'number',
          order: 'desc',
          label: '物件番号順',
        },
        {
          orderBy: 'view_count',
          order: 'desc',
          label: '閲覧回数順',
        },
        {
          orderBy: 'price',
          order: 'asc',
          label: '価格が安い順',
        },
        {
          orderBy: 'latest_update',
          order: 'desc',
          label: '最終更新順',
        },
      ],
      properties: [],
      mapDropPins: [],
      timeoutId: null,
      keyword: null,
      page: 1,
      total: 0,
      params: null,
    };
  },
  created() {
    this.setValuesFromQuery(this.$route);
    this.getProperties();
    this.getCategories();
    this.getTypes('deal');
    this.getTypes('prop');
  },
  computed: {
    ...mapState(['user', 'layout']),
    likeIds() {
      return this.user.likes.likeIds.length;
    },
    selectedOrderBy() {
      const item = this.orderByOptions.find((opt) => opt.orderBy === this.orderBy);
      return item.label;
    },
    adjstedCategories() {
      const size = this.layout?.displaySize;
      const obj = this.searchItems.find((item) => item.name === 'categories');
      const ctgs = obj.options;
      return {
        even: size && ['lg', 'md'].includes(size) ? ctgs : ctgs.filter((_, i) => i % 2 === 0),
        odd: size && ['lg', 'md'].includes(size) ? [] : ctgs.filter((_, i) => i % 2 === 1),
      };
    },
    // 検索条件のラベルの配列
    searchItemLabels() {
      let arr = [];
      this.searchItems.forEach((item) => {
        const labels = item.options.filter((opt) => item.selected.includes(opt.id)).map((opt) => opt.label || opt.name);
        arr = [...arr, ...labels];
      });
      return arr;
    },
  },
  watch: {
    likeIds() {
      this.getProperties('updateLike');
    },
  },
  methods: {
    isChecked(name, value) {
      const target = this.searchItems.find((item) => item.name === name);
      return target && target.selected.find((s) => s === value);
    },

    changeOrder(orderBy) {
      this.orderBy = orderBy;
      this.flag.showOrderOption = false;
      this.changeSearchOption();
    },

    changeSearchOption() {
      this.page = 1;
      this.getProperties();
    },

    showMore() {
      this.page += 1;
      this.getProperties();
    },

    getProperties(type) {
      this.putUpPin();
      this.flag.isLoading.properties = true;
      let params = {};
      const targetColumn = ['city', 'town', 'address', 'label', 'landmark', 'current_status', 'station', 'bus_station', 'city_planning', 'use_area', 'facility', 'number', 'remarks', 'category'];
      if (this.keyword) {
        params.targetColumn = targetColumn;
        params.keyword = this.keyword.replaceAll('　', ' ').split(' ');
      }

      params.limit = 8;
      params.page = this.page;

      this.searchItems.forEach((item) => {
        params[item.name] = item.selected;
      });

      // 並び順指定
      const order = this.orderByOptions.find((opt) => opt.orderBy === this.orderBy);
      const orderParams = {
        orderBy: order?.orderBy,
        order: order.order,
      };

      params = {
        ...params,
        ...orderParams,
      };

      if (type === 'updateLike') {
        params.limit = 8 * this.page;
        params.page = 1;
      }

      if (this.orderBy === 'price') {
        params.excludeNegotiate = 1;
      }

      // 最新の検索条件をthis.paramsに保存し、返却時にthis.paramsとparamsのkeywordが一致する場合に結果を格納するようにする
      // （フリーワードinputでのイベント発火により、短時間に複数のリクエストがされるため、返却の順番がリクエスト送信の順番と一致せず最新の結果がそれ以前のリクエストの結果に上書きされてしまうことを避ける）
      this.params = params;

      this.axios({
        method: 'get',
        url: '/v1/property/get/list',
        params,
      })
        .then((response) => {
          const isSameLength = this.params.keyword?.length === params.keyword?.length;
          const isSameWords = !this.params.keyword || !params.keyword || !this.params.keyword.some((w) => !params.keyword.includes(w));
          if (isSameLength && isSameWords) {
            const res = response.data;
            const data = res.properties.data;
            this.total = res.properties.meta.total;
            if (this.page === 1 || type === 'updateLike') this.properties = [];
            this.properties = [...this.properties, ...data];
          }
          this.flag.isLoading.properties = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    putUpPin() {
      let params = {};
      const targetColumn = ['label', 'current_status'];
      if (this.keyword) {
        params.targetColumn = targetColumn;
        params.keyword = this.keyword.replaceAll('　', ' ').split(' ');
      }
      params.page = this.page;

      this.searchItems.forEach((item) => {
        params[item.name] = item.selected;
      });

      params = {
        ...params,
      };

      this.axios({
        method: 'get',
        url: '/v1/property/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          const data = res.properties.data;
          if (this.page === 1) this.mapDropPins = [];
          this.mapDropPins = [...this.mapDropPins, ...data];
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    getCategories() {
      const params = {};
      this.axios({
        method: 'GET',
        url: '/v1/category/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.setOptions('categories', res.categories.data);
          this.flag.isLoading.categories = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    getTypes(name) {
      const params = {};
      this.axios({
        method: 'GET',
        url: `/v1/${name}Type/get/list`,
        params,
      })
        .then((response) => {
          const res = response.data;
          this.setOptions(`${name}Types`, res[`${name}Types`].data);
          this.flag.isLoading[`${name}Types`] = false;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    setOptions(name, arr) {
      this.searchItems.forEach((item) => {
        if (item.name === name) item.options = arr;
      });
    },

    changeOptions(name, value) {
      const type = this.isChecked(name, value) ? 'remove' : 'add';
      this.searchItems.forEach((item) => {
        if (item.name === name) {
          item.selected = item.selected.filter((s) => s !== value);
          if (type === 'add') item.selected.push(value);
          this.updateQuery(item.name, item.selected);
        }
      });
      this.changeSearchOption();
    },

    addViewCount(id) {
      cf.addViewCount(id);
    },

    updateQuery(key, values) {
      const newQuery = { ...this.$route.query };
      if (values && values.length > 0) {
        newQuery[key] = values.join(',');
      } else {
        delete newQuery[key];
      }
      this.$router.push({ query: newQuery });
    },

    setValuesFromQuery(route) {
      this.searchItems.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(route.query, item.name)) {
          item.selected = route.query[item.name].split(',').map((value) => {
            if (item.select_label === 'id') {
              return parseInt(value, 10);
            }
            return value;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  padding-top: var(--header-height);
}
.head {
  padding: 100px 20px 200px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-image: url('/images/akiya_top.png');
  background-size: cover;
  background-position: center;
  .title {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
  }
  .arc {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: calc(100% * (1712 / 1440));
    max-width: none;
  }
}
.main {
  position: relative;
  z-index: 1;
  margin-top: calc(-100% * (1712 / 1440) * (193 / 1712) / 2 - 30px);
}
.note {
  text-align: center;
  font-weight: 700;
  opacity: .6;
}

.searchItems {
  > li {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.categories {
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -16px;
    > li {
      width: fit-content;
      white-space: nowrap;
      margin-right: 16px;
      margin-bottom: 16px;
    }
  }
  @include smView {
    overflow-x: scroll;
    overflow-y: hidden;
    > ul {
      flex-wrap: nowrap;
      &:nth-child(1) {
        margin-bottom: 0;
      }
    }
  }
}

.keyword {
  margin-top: 48px;

  input {
    margin: 0 auto;
    width: 100%;
    max-width: 560px;
    display: block;
    padding: 15px 24px;
    border-radius: 60px;
    border: none;
    outline: none;
    background-color: var(--surface-black);
    &::placeholder {
      font-weight: 700;
      color: var(--label-secondary);
    }
  }

  @include smView {
    margin-top: 32px;
  }
}

.down {
  margin-right: 6px;
}

.options {
  &_wrap {
    position: relative;
  }
  &_btn {
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid var(--label-primary);
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  background-color: var(--surface-white);
  border-radius: 28px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  z-index: 10;
  padding: 32px;
  font-size: 14px;
  right: 0;
  > li {
    &:not(:first-child) {
      margin-top: 32px;
    }
  }
}

.bar {
  position: fixed;
  top: 132px;
  left: 50%;
  transform: translate(-50%);
  width: 90%;
  max-width: 800px;
  z-index: 3;
  @include smView {
    top: 106px;
  }
}

.label {
  font-size: 13px;
  opacity: .7;
  margin-bottom: 12px;
}

.map {
  &_wrap {
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
  }
  &_label {
    font-size: 28px;
    margin-bottom: 48px;
    font-weight: 700;
    text-align: center;
  }
  border-radius: 28px;
  border: 2px solid var(--border-point-gray);
  width: 100%;
  padding-top: calc(100% * 469 / 1080);
  background-image: url('/images/map.png');
  background-size: cover;
  background-position: center;
  @include smView {
    padding-top: 100%;
  }
}

@include smView {
  .wrap {
    padding-top: 0;
  }
  .head {
    padding: 185px 20px 200px;
    .title {
      font-size: 40px;
    }
  }
}
</style>
