import router from '@/router';
/**
 * View情報管理
*/

const actions = {
  // TODO: 定数化
  setDisplaySize({ commit }, windowWidth) {
    if (windowWidth >= 1024) {
      commit('setDisplaySize', 'lg');
    } else if (windowWidth >= 767) {
      commit('setDisplaySize', 'md');
    } else if (windowWidth >= 375) {
      commit('setDisplaySize', 'sm');
    } else {
      commit('setDisplaySize', 'xs');
    }
  },
  
  setHeaderStatus({ commit, state }, args = { first: false, second: false }) {
    const path = router.currentRoute._value.path;

    const headerState = state.header;
    const scrolledState = {
      first: args.first,
      second: args.second,
    };

    if (path !== '/') {
      headerState.isShow = true;
      headerState.isShowTitle = true;
      headerState.color = 'semi-transparent-white';
    } else {
      headerState.isShow = args.second;
      headerState.isShowTitle = args.second;

      const size = ['lg', 'md'].includes(state.displaySize) ? 'lg' : 'sm';
      let color = 'solid-white';
      if (size === 'lg') {
        color = args.first ? 'semi-transparent-white' : 'solid-white';
      } else {
        headerState.isShowTitle = args.first;
        color = args.first ? 'semi-transparent-white' : 'transparent';
      }
      headerState.color = color;
    }

    const params = {
      scrolledState,
      headerState,
    };
    commit('setHeaderState', params);
  },
};

const mutations = {
  setDisplaySize(state, size) {
    state.displaySize = size;
  },
  setHeaderState(state, params) {
    state.header = params.headerState;
    state.scrolled = params.scrolledState;
  },
};

const state = {
  sizeChecked: false,
  displaySize: 'lg',
  scrolled: {
    first: false,
    second: false,
  },
  header: {
    isShow: true,
    color: 'solid-white',
    isShowTitle: false,
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
