<template>
  <div>
    <div v-if="properties">
      <ul :class="s.list">
        <li
          v-for="propType in types"
          :key="propType.id">
          <img
            :class="s.icon"
            :src="`/images/map_pins/pin_${propType.id}.svg`">
          <p :class="s.label">{{ propType.name }}</p>
        </li>
      </ul>
      <div ref="map" :class="s.map"></div>
    </div>
  </div>
</template>

<script>
import { Loader } from 'google-maps';

export default {
  name: 'MapPoints',
  props: {
    properties: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      flag: null,
      propertyDatas: [],
      googleApisUrl: '',
      map: null,
      marker: [],
      infoWidow: [],
      google: null,
      types: [],
    };
  },
  created() {},
  async mounted() {
    this.getPropTypes();
    let loader;
    if (process.env && process.env.VUE_APP_GOOGLE_MAP_API_KEY) {
      loader = new Loader(process.env.VUE_APP_GOOGLE_MAP_API_KEY);
      this.google = await loader.load();
    } else return true;

    this.map = new this.google.maps.Map(this.$refs.map, {
      center: { lat: 36.411859, lng: 139.3304967 }, // 桐生駅
      zoom: 12,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
    });

    if (this.properties.length) {
      this.properties.forEach((data, index) => {
        this.marker[index] = new this.google.maps.Marker({
          position: { lat: Number(data.latitude), lng: Number(data.longitude) }, // 桐生駅
          map: this.map,
          icon: {
            url: data.propTypes?.[0] ? `/images/map_pins/pin_${data.propTypes[0].id}.svg` : '/images/map_pins/no_type.svg',
          },
        });

        this.infoWidow[index] = new this.google.maps.InfoWindow({
          content: `<div class="sample"><a href="/bukken/${data.number}/" target="_blank" style="color: #2E2E2E; text-decoration: underline; padding: 4px 8px;">${data.label}</a></div>`,
        });

        // ピンのクリックイベント登録
        this.marker[index].addListener('click', () => {
          this.infoWidow.forEach((elem) => elem.close()); // 他のピンをクリックしたら前に開いていたピンの情報を閉じる
          this.infoWidow[index].open(this.map, this.marker[index]);
        });
      });
    }
  },
  watch: {
    properties: {
      handler() {
        if (this.google) this.updateMaps();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    getPropTypes() {
      this.axios({
        method: 'GET',
        url: '/v1/propType/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.types = res.propTypes.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    async updateMaps() {
      this.map = new this.google.maps.Map(this.$refs.map, {
        center: this.map.center,
        zoom: this.map.zoom,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      });

      if (this.properties.length) {
        this.properties.forEach((data, index) => {
          this.marker[index] = new this.google.maps.Marker({
            position: { lat: Number(data.latitude), lng: Number(data.longitude) }, // 桐生駅
            map: this.map,
            icon: {
              url: data.propTypes?.[0] ? `/images/map_pins/pin_${data.propTypes[0].id}.svg` : '/images/map_pins/no_type.svg',
            },
          });

          //
          this.infoWidow[index] = new this.google.maps.InfoWindow({
            content: `<div class="sample"><a href="/bukken/${data.number}/" target="_blank" style="color: #2E2E2E; text-decoration: underline; padding: 4px 8px;">${data.label}</a></div>`,
          });

          // ピンのクリックイベント登録
          this.marker[index].addListener('click', () => {
            this.infoWidow.forEach((elem) => elem.close()); // 他のピンをクリックしたら前に開いていたピンの情報を閉じる
            this.infoWidow[index].open(this.map, this.marker[index]);
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.map {
  width:  100%;
  padding-top: 50%;
  border-radius: 28px;
  border: 2px solid var(--border-point-gray);
  @include smView {
    padding-top: 100%;
  }
}
.list {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  > li {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0 12px;
    img {
      height: 25px;
    }
    .label {
      white-space: nowrap;
      margin-left: 8px;
    }
  }
}
</style>
