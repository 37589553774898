<template>
  <div :class="s.wrap">
    <div :class="s.container">
      <div :class="s.edge">
        <div :class="s.icon_wrap">
          <div class="sp" :class="s.text">
            <div :class="s.count"><slot/></div>
            <div :class="s.selectedItems" v-html="labels2String"/>
          </div>
        </div>
        <div :class="s.select_wrap">
          <div
            @click="flag.showOption = !flag.showOption"
            :class="s.select"><span :class="s.down"/>{{ selected.label }}</div>
          <transition name="fade">
            <ul
              v-if="flag.showOption && options.length"
              :class="s.options">
              <li
                v-for="opt in options"
                :key="opt.orderBy"
                @click="changeOrder(opt.orderBy)">{{ opt.label }}</li>
            </ul>
          </transition>
        </div>
      </div>
      <div :class="[s.center, s.pc]">
        <div :class="s.center_title">
          <slot/>
        </div>
        <div :class="s.selectedItems" v-html="labels2String"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'search-item-bar',
  props: {
    options: {
      type: Array,
    },
    initTarget: {
      type: String,
    },
    searchOptionLabels: {
      type: Array,
    },
  },
  data() {
    return {
      flag: {
        showOption: false,
      },
      orderBy: null,
    };
  },
  created() {
    this.orderBy = this.initTarget;
  },
  computed: {
    selected() {
      return this.options.find((opt) => opt.orderBy === this.orderBy);
    },
    labels2String() {
      let str = '';
      const separator = `<span class="${this.s.separator}">|</span>`;
      this.searchOptionLabels.forEach((item, i) => {
        if (i) str += `${separator}${item}`;
        else str = item;
      });
      return str;
    },
  },
  methods: {
    changeOrder(orderBy) {
      this.orderBy = orderBy;
      this.flag.showOption = false;
      this.$emit('changeOrder', orderBy);
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  height: 64px;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.16);
  border-radius: 100px;
  background-color: var(--surface-white);
  padding: 16px;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: calc(100% - 260px);
  &_title {
    font-size: 14px;
    font-weight: 700;
  }
}
.selectedItems {
  margin-top: 6px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.separator {
  color: var(--border-primary);
  margin: 0 12px;
}

.pc {
  @include smView {
    display: none;
  }
}

.edge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .select {
    &_wrap {
      position: relative;
      font-size: 14px;
    }

    padding: 8px 13px;
    border: 1px solid var(--label-primary);
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .down {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url('/images/front_icons/triangle_down.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 13px;
    }
    @include smView {
      font-size: 11px;
    }
  }
  .options {
    position: absolute;
    top: calc(100% + 8px);
    padding: 32px;
    background-color: var(--surface-white);
    width: fit-content;
    border-radius: 28px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.16);
    > li {
      cursor: pointer;
      transition: all .3s;
      &:not(:first-child) {
        margin-top: 32px;
        white-space: nowrap;
      }
      &:hover {
        opacity: .4;
      }
    }
  }

  @include smView {
    .icon {
      flex-shrink: 0;
      &_wrap {
        width: calc(100% - 130px);
        display: flex;
        align-items: center;
        .text {
          margin-left: 12px;
          width: calc(100% - 20px);
        }
        .count {
          font-weight: 700;
          font-size: 14px;
        }
        .items {
          display: flex;
          margin-top: 6px;
          font-size: 10px;
          color: var(--label-secondary);
          line-height: 1;
          > li {
            &:not(:first-child) {
              padding-left: 8px;
              margin-left: 8px;
              border-left: 1px solid var(--border-primary);
            }
          }
        }
      }
    }
  }
}
</style>
