<template>
  <div :class="s.base">
    <div
      v-if="properties.length"
      :class="s.wrap" id="wrap">
      <ul :class="s.list" id="list">
        <li v-for="p in properties" :key="p.id">
          <router-link :to="`/bukken/${p.number}/`" @click="addViewCount(p.id)" target="_blank">
            <PropertyCard :propData="p"/>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions.js';
import PropertyCard from './PropertyCard.vue';

export default {
  name: 'horizontal',
  mixins: [cf],
  props: {
    properties: {
      type: Array,
    },
  },
  components: {
    PropertyCard,
  },
  methods: {
    addViewCount(id) {
      cf.addViewCount(id);
    },
  },
};
</script>

<style lang="scss" module="s">
$track: rgba(240, 240, 240, .5);
$thumb: rgba(0, 0, 0, 0.05);

.wrap {
  border-radius: 28px;
  width: 100%;
  overflow-x: scroll;

  scrollbar-color: $thumb $track;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $track;
    border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    background: $thumb;
    border-radius: 100px;
  }
}
.list {
  display: flex;
  width: fit-content;
  padding-bottom: 32px;
  > li {
    width: 252px;
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}
</style>
