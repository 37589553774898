<template>
  <div :class="s.base">
    <div :class="s.wrap">
      <div :class="[s.content, s.sp]">
        <div
          :class="[s.img]"
          @click="clicked(0)"><img :src="medias[0].url" alt=""></div>
      </div>
      <div :class="[s.contents, s.pc]">
        <!-- 1枚 -->
        <div
          v-if="medias.length === 1"
          :class="[s.img, s.h_half]"
          @click="clicked(0)"><img :src="medias[0].url" alt=""></div>

        <!-- 2枚以上 -->
        <div
          v-if="medias.length >= 2"
          :class="[s.flex]">
          <!-- 1枚目は共通 -->
          <div :class="[s.w_half, s.pdg]">
            <div
              :class="[s.img, s.h_full]"
              @click="clicked(0)"><img :src="medias[0].url" alt=""></div>
          </div>

          <!-- 2枚目以降 -->
          <div :class="[s.w_half]">

            <!-- 2枚の時 -->
            <div
              v-if="medias.length === 2"
              :class="[s.img, s.h_full]"
              @click="clicked(1)"><img :src="medias[1].url" alt=""></div>
            
            <!-- 3枚以上 -->
            <div v-else>

              <!-- 2枚目は共通 -->
              <div :class="[s.pdg]">
                <div
                  :class="[s.img, s.h_half, s.minus]"
                  @click="clicked(1)"><img :src="medias[1].url" alt=""></div>
              </div>
                
              <!-- 3枚の時 -->
              <div
                v-if="medias.length === 3"
                :class="[s.pdg]">
                <div
                  :class="[s.img, s.h_half, s.minus]"
                  @click="clicked(2)"><img :src="medias[2].url" alt=""></div>
              </div>

              <!-- 4枚以上 -->
              <div
                v-else
                :class="[s.flex]">

                <!-- 3枚目は共通 -->
                <div :class="[s.w_half, s.pdg]">
                  <div
                    :class="[s.img, s.h_full]"
                    @click="clicked(2)"><img :src="medias[2].url" alt=""></div>
                </div>

                <!-- 4枚の時 -->
                <div
                  v-if="medias.length === 4"
                  :class="[s.w_half, s.pdg]">
                  <div
                    :class="[s.img, s.h_full]"
                    @click="clicked(3)"><img :src="medias[3].url" alt=""></div>
                </div>

                <!-- 5枚以上 -->
                <div
                  v-else
                  :class="[s.w_half]">
                  

                  <!-- 4枚目は共通 -->
                  <div
                    :class="[s.pdg]">
                    <div
                      :class="[s.img, s.h_half, s.minus]"
                      @click="clicked(3)"><img :src="medias[3].url" alt=""></div>
                  </div>

                  <!-- 5枚の時 -->
                  <div
                    v-if="medias.length === 5"
                    :class="[s.pdg]">
                    <div
                      :class="[s.img, s.h_half, s.minus]"
                      @click="clicked(4)"><img :src="medias[4].url" alt=""></div>
                  </div>

                  <!-- 6枚以上 -->
                  <div
                    v-else
                    :class="[s.flex]">

                    <!-- 5枚目は共通 -->
                    <div
                      :class="[s.w_half, s.pdg]">
                      <div
                        :class="[s.img, s.h_full]"
                        @click="clicked(4)"><img :src="medias[4].url" alt=""></div>
                    </div>

                    <!-- 6枚の時 -->
                    <div
                      v-if="medias.length === 6"
                      :class="[s.w_half, s.pdg]">
                      <div
                        :class="[s.img, s.h_full]"
                        @click="clicked(5)"><img :src="medias[5].url" alt=""></div>
                    </div>

                    <!-- 7枚以上 -->
                    <div
                      v-else
                      :class="[s.w_half]">
                      <div
                        v-for="n in [5, 6]"
                        :key="n"
                        :class="[s.pdg]">
                        <div
                          :class="[s.img, s.h_half, s.minus]"
                          @click="clicked(n)"><img :src="medias[n].url" alt=""></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="s.showAll"
      v-if="((medias.length > 7) || (!['lg', 'md'].includes(layout.displaySize) && medias.length > 1)) && showMore"
      v-on:click="clicked(0)">
      <i class="fa-regular fa-image"/>すべての写真を表示（{{ medias.length }}枚）
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'property-detail',
  props: {
    medias: {
      type: Array,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(['layout']),
    adjustedImages() {
      const obj = {
        lg: [],
        md: [],
        sm: [],
      };
      this.medias.forEach((m, i) => {
        if (i < 1) obj.lg.push(m);
        else if (i < 5) obj.md.push(m);
        else if (i < 9) obj.sm.push(m);
      });
      return obj;
    },
  },
  methods: {
    showViewer(id) {
      this.$emit('clickedImg', id);
    },
    clicked(idx) {
      this.$emit('clicked', idx);
    },
  },
};
</script>

<style lang="scss" module="s">
.base {
  position: relative;
  width: 100%;
}

$pdg: 4px;
.wrap {
  width: 100%;
  overflow: hidden;
  border-radius: 28px;
  position: relative;
  z-index: 1;
}

.contents {
  margin: -$pdg;
}

.img {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.flex {
  display: flex;
}
.pdg {
  padding: $pdg;
}

.h_half {
  padding-top: 50%;

  &.minus {
    padding-top: calc(50% - 1 * $pdg);
  }
}
.h_full {
  padding-top: 100%;
}

.w_half {
  width: 50%;
}

.showAll {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 12px 40px;
  border-radius: 100px;
  background-color: var(--surface-white);
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  transition: all .3s;
  text-align: center;

  &:hover {
    color: var(--label-secondary);
  }
  .icon {
    margin-right: 8px;
  }
}

.sp {
  display: none;
}

@include smView {
  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  .img {
    padding-top: calc(100% * 24 / 35);
  }

  .showAll {
    width: 300px;
  }
}

@media print {
  .pc {
    display: block;
  }
  .sp {
    display: none;
  }
  .img {
    &.h_half {
      padding-top: 50%;
      &.minus {
        padding-top: calc(50% - 1 * $pdg);
      }
    }
    &.h_full {
      padding-top: 100%;
    }
  }
}
</style>
