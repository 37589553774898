<template>
  <div :class="s.container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'container',
};
</script>

<style lang="scss" module="s">
.container {
  overflow: visible;
  padding: 0 20px;
  max-width: 1080px;
  margin: 0 auto;
}
</style>
