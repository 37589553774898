<template>
  <div
    :class="[s.wrap, flag.isActive ? s.isActive : '']"
    v-if="flag.isLoaded && properties.length && isShowPage">
    <div :class="[s.bg, flag.isActive ? s.isActive : '']"/>
    <div
      :class="[
        s.menu,
        flag.isActive ? s.isActive : '',
      ]">
      <div :class="[s.label_wrap, flag.isActive ? s.isActive : '' ]">
        <div :class="s.label">
          <div class="d-flex align-items-center">
            <div :class="s.star"/>
            <div :class="s.like">
              <span>お気に入り</span>
              <span class="pc">（{{ properties.length }}件）</span>
              <span class="sp" :class="s.like_count">{{ properties.length }}件</span>
            </div>
          </div>
          <transition name="fade">
            <basic-btn
              v-if="flag.isActive"
              :tag="'a'"
              :openOtherTab="true"
              :width="'fit-content'"
              :size="'sm'"
              :type="'bdr'"
              link="/bukken/print/likes/">プリントする</basic-btn>
          </transition>
        </div>
        <div :class="s.right">
          <ul
            :class="[
              s.imgs,
              flag.isActive ? s.isActive : '',
          ]">
            <li
              v-for="p in top3Properties" :key="p.id"
              :style="`background-image: url(${getThumb(p.medias).url});`"/>
          </ul>
          <div
            @click="flag.isActive = !flag.isActive"
            :class="[
              s.btn,
              flag.isActive ? s.isActive : '',
            ]"><span/><span/></div>
        </div>
      </div>
      <div
        :class="[
          s.list_wrap,
          flag.isActive ? s.isActive : '',
        ]">
        <ul :class="s.list">
          <li v-for="p in properties"
            :key="p.id">
            <PropertyCard
              :labelSize="14"
              :showLikeIcon="!['lg', 'md'].includes(layout.displaySize)"
              :propData="p"
              :showItems="[]"
              :size="'sm'"
              @click="jump2Detail(p)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import {
  PropertyCard,
  BasicBtn,
} from '@/components/parts';

export default {
  name: 'bottom-menu',
  mixins: [cf],
  components: {
    PropertyCard,
    BasicBtn,
  },
  data() {
    return {
      flag: {
        isActive: false,
        isLoaded: false,
      },
      properties: [],
      layout: {
        displaySize: 'lg',
      },
    };
  },
  computed: {
    ...mapState(['user']),
    top3Properties() {
      return this.properties.filter((_, i) => i < 3);
    },
    likes() {
      return this.user.likes.likeIds;
    },
    isShowPage() {
      return !this.$route.path.includes('print');
    },
  },
  mounted() {
    this.getLikes();
  },
  watch: {
    likes() {
      this.getLikes();
    },
  },
  methods: {
    getLikes() {
      const ls = cf.getLocalStorage('akiya');
      if (!ls || !ls.likes || !ls.likes.length) {
        this.properties = [];
        return;
      }
      this.getProperties(ls.likes);
    },
    getProperties(ids) {
      this.axios({
        method: 'get',
        url: '/v1/property/get/list',
        params: { ids },
      })
        .then((response) => {
          const res = response.data;
          this.properties = res.properties.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.isLoaded = true;
        });
    },
    jump2Detail(data) {
      const url = this.$router.resolve(`/bukken/${data.number}`);
      window.open(url.href);
      cf.addViewCount(data.id);
      this.flag.isActive = false;
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 80;
  width: 100%;
  &.isActive {
    height: 100%;
  }

  .bg {
    display: none;
    transition: all .3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.isActive {
      display: block;
      background-color: rgba(0, 0, 0, 0.16);
    }
  }
}
.menu {
  transition: all .3s;
  position: absolute;
  font-size: 18px;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 13px 28px 11px 40px;
  z-index: 2;
  background: #ffffff;
  border-radius: 0 34px 0 0;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
  max-width: 1220px;


  &.isActive {
    border-radius: 0 112px 0 0;
    padding: 31px 56px 0px 44px;
    .label {
      font-size: 24px;
    }
  }

  .label {
    &_wrap {
      display: flex;
      align-items: center;

      &.isActive {
        justify-content: space-between;
      }
    }

    transition: all .3s;
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: 16px;

    .star {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-image: url('/images/front_icons/star_active.svg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    @include smView {
      display: block;
    }
  }

  .right {
    display: flex;
    align-items: center;
  }

  .imgs {
    transition: all .3s;
    margin-left: 24px;
    display: flex;
    align-items: center;
    > li {
      background-position: center;
      background-size: cover;
      width: 48px;
      height: 48px;
      border-radius: 16px;
      border: 2px solid #ffffff;
      &:nth-child(n + 2) {
        margin-left: -8px;
      }
    }

    &.isActive {
      display: none;
    }
  }
}

@include smView {
  .menu {
    padding: 16px 20px;
    width: 100%;
  }
  .label {
    &_wrap {
      justify-content: space-between;
    }
  }
  .like {
    &_count {
      font-weight: 400;
      font-size: 12px;
      color: var(--label-secondary);
    }
  }
}

.btn {
  margin-left: 16px;
  width: 24px;
  height: 24px;
  background-color: var(--label-primary);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  span {
    position: absolute;
    height: 2px;
    width: 8px;
    transform-origin: 0px;
    left: 50%;
    top: 35%;
    border-radius: 2px;
    background-color: var(--surface-white);
    &:first-child {
      transform: rotate(40deg) translate(-1px);
    }
    &:last-child {
      transform: rotate(-40deg) translate(calc(-100% + 1px), 0);
    }
  }

  &.isActive {
    width: 49px;
    height: 49px;
    transform-origin: center;
    background-color: transparent;
    border: 1px solid var(--surface-field-gray);
    span {
      width: 20px;
      top: 50%;
      left: 50%;
      background-color: var(--label-primary);
      &:first-child {
        transform: translate(-7px, -7px) rotate(45deg);
      }
      &:last-child {
        transform: translate(-7px, 7px) rotate(-45deg);
      }
    }
  }
}

.list {
  &_wrap {
    transition: all .3s;
    overflow-x: scroll;
    padding: 0 0 0;
    opacity: 0;
    height: fit-content;
    width: fit-content;
    max-width: 0;
    max-height: 0;

    &.isActive {
      opacity: 1;
      padding: 48px 0 64px;
      max-width: 100%;
      max-height: 500px;
    }
  }

  display: flex;
  width: 100%;
  > li {
    cursor: pointer;
    width: 200px;
    flex-shrink: 0;
    &:not(:first-child) {
      margin-left: 32px;
    }
  }
}

@include smView {
  .label {
    &_wrap {
      &.isActive {
        padding-bottom: 32px;
      }
    }
  }
  .list {
    display: block;
    &_wrap {
      &.isActive {
        padding-top: 0;
      }
    }

    &.isActive {
      max-height: 600px;
      max-width: 100%;
    }

    > li {
      width: 100%;
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 39px;
      }
    }
  }
}
</style>
