<template>
  <div :style="style">
    <spacer :y="22.5" :smY="14"/>
    <Container>
      <div v-if="property">
        <div :class="s.img_wrap">
          <PropertyDetailTopImg
            v-if="property.medias.length"
            :medias="property.medias"
            @clicked="showViewer"/>
        </div>
        <Viewer
          v-if="flag.showViewer"
          :medias="property.medias"
          :initIdx="viewerInitIdx"
          @close="flag.showViewer = false"/>
        <spacer :y="4" :smY="7"/>
        <div :class="s.labels">
          <div :class="s.typelabel">{{ property.propTypes[0].name }}</div>
          <basic-btn
            :class="s.pc"
            :type="'bdr'"
            :size="'sm'"
            :width="'fit-content'"
            :tag="'a'"
            :link="`/bukken/print/${property.number}/`"
            :openOtherTab="true">プリントする</basic-btn>
        </div>
        <spacer :y="4"/>
        <div :class="s.label">{{ property.label }}</div>
        <spacer :y="3"/>
        <div :class="s.price_wrap">
          <div :class="s.price">
            <span :class="s.price_label">{{ property.dealTypes[0].is_rent ? '賃料（月額）' : '売却希望価格' }}</span>
            <span :class="s.price_separator"/>
            <span v-if="property.price && !property.is_negotiated">&yen;{{ property.price.toLocaleString() }}</span>
            <span v-else>応相談</span>
          </div>
          <div>
            <div v-if="flag.isLoading.like">Loading...</div>
            <div
              v-else
              :class="s.fav"
              @click="changeLikeStatus">
              <div :class="[s.star, isLiked ? s.active : '']"/>
              <div>{{ flag.isLiked ? 'お気に入りに登録しました' : 'お気に入りに登録する' }}</div>
            </div>
          </div>
        </div>
        <spacer :y="3.5"/>
        <div :class="s.bgbox">
          <div :class="s.detail_title">物件概要</div>
          <spacer :y="4"/>
          <FlexList
            :spaceX="80"
            :spaceY="24"
            :wrap="true">
            <li
              v-for="item in items"
              :key="item.name"
              :class="[s.detail_list, item.width === 'narrow' ? s.narrow : s.wide]">
              <div :class="s.detail_item">
                <div :class="s.detail_label" v-html="item.label"/>
                <div :class="s.detail_value" class="n2br">
                  <span v-if="item.name === 'land_area'">{{ landArea }}</span>
                  <span v-else-if="item.name === 'building_area'" v-html="buildingArea"/>
                  <span v-else-if="item.name === 'address'">{{ address }}</span>
                  <span v-else-if="item.name === 'category'">{{ categoryStr }}</span>
                  <span v-else class="n2br" v-html="property[item.name] || '-'"/>
                </div>
              </div>
            </li>
          </FlexList>
          <spacer :y="3"/>
          <ul :class="s.detail">
            <li v-for="wItem in items.wide" :key="wItem.name">
              <div :class="s.detail_item">
                <div :class="s.detail_label">{{ wItem.label }}</div>
                <div :class="s.detail_value">
                  <span class="n2br" v-html="property[wItem.name]"/>
                </div>
              </div>
            </li>
          </ul>
          <spacer :y="7" v-if="property.floors.length"/>
          <div v-if="property.floors.length">
            <div :class="s.detail_title">間取図</div>
            <spacer :y="2"/>
            <div
              v-for="(floor, i) in property.floors"
              :key="floor.id"
              :class="s.floor">
              <spacer :y="2" v-if="i" />
              <img :src="floor.url" :alt="property.label">
            </div>
          </div>
          <div
            v-if="property.movie_link"
            :class="s.movie_link">
            <a
              :href="property.movie_link"
              target="_blank">物件番号{{ property.number }}YouTube桐生市チャンネル【kiryucitychannel】</a>
          </div>
          <spacer :y="7"/>
          <div :class="s.map" v-if="property.longitude && property.latitude">
            <iframe
              :src="`https://maps.google.co.jp/maps?ll=${property.latitude},${property.longitude}&amp;q=${property.latitude},${property.longitude}&amp;output=embed&amp;t=m&amp;z=17`"
              frameborder="0"
              scrolling="no"/>
          </div>
        </div>

        <div v-if="property.estates.length">
          <spacer :y="4"/>
          <div :class="s.bgbox">
            <div :class="s.detail_title">この物件に関するお問い合わせ</div>
            <spacer :y="4"/>
            <FlexList
              :spaceX="80"
              :spaceY="24"
              :count="2"
              :smCount="1"
              :wrap="true">
              <li v-for="item in estateItems" :key="item.name">
                <div :class="s.detail_item">
                  <div :class="s.detail_label" v-html="item.label"/>
                  <div :class="s.detail_value">{{ property.estates[0][item.name] || '-' }}</div>
                </div>
              </li>
            </FlexList>
          </div>
        </div>

        <div v-if="properties.categories.length">
          <spacer :y="15"/>
          <!-- 同一categoryが登録されている物件 -->
          <div :class="s.section_title">条件の近い物件</div>
          <HorizontalList
            v-if="['lg', 'md'].includes(layout.displaySize)"
            :properties="properties.categories"/>
          <div :class="s.relations" v-else>
            <FlexList
              :wrap="1"
              :count="1"
              :spaceY="32">
              <li
                v-for="property in properties.view"
                :key="property.id">
                <router-link :to="`/bukken/${property.number}/`" @click="addViewCount(p.id)" target="_blank">
                  <PropertyCard :propData="property"/>
                </router-link>
              </li>
            </FlexList>
          </div>
        </div>

        <div v-if="properties.view.length">
          <spacer :y="15"/>
          <!-- property.view_countの降順 -->
          <div :class="s.section_title">よくみられている物件</div>
          <HorizontalList
            :properties="properties.view"
            v-if="['lg', 'md'].includes(layout.displaySize)"/>
          <div :class="s.relations" v-else>
            <FlexList
              :wrap="1"
              :count="1"
              :spaceY="32">
              <li
                v-for="property in properties.view"
                :key="property.id">
                <PropertyCard :propData="property"/>
              </li>
            </FlexList>
          </div>
        </div>
      </div>
      <div
        :class="s.notFound"
        v-else-if="flag.notFound">
        <div>お探しの物件は見つかりませんでした。</div>
        <spacer :y="4"/>
        <BasicBtn
          tag="a"
          :link="'/'">トップページへ</BasicBtn>
      </div>
      <Loader v-else/>
    </Container>

    <spacer :y="30"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import {
  Container,
  FlexList,
  PropertyCard,
  // BasicBox,
  Spacer,
  // CheckBtn,
  BasicBtn,
  PropertyDetailTopImg,
  HorizontalList,
  Viewer,
  Loader,
} from '@/components/parts';

export default {
  name: 'property-detail',
  mixins: [cf],
  components: {
    Container,
    FlexList,
    PropertyCard,
    // BasicBox,
    Spacer,
    // CheckBtn,
    BasicBtn,
    PropertyDetailTopImg,
    HorizontalList,
    Viewer,
    Loader,
  },
  data() {
    return {
      flag: {
        isLoading: {
          like: false,
        },
        showViewer: false,
        notFound: false,
      },
      property: null,
      properties: {
        view: [],
        categories: [],
      },
      viewerInitIdx: 0,
      items: [
        {
          name: 'number',
          label: '物件番号',
          width: 'wide',
        },
        {
          name: 'address',
          label: '所在地',
          width: 'wide',
        },
        {
          name: 'station',
          label: '最寄り駅',
          width: 'wide',
        },
        {
          name: 'bus_station',
          label: '最寄り<br class="sp">バス停',
          width: 'wide',
        },
        {
          name: 'land_area',
          label: '土地面積',
          width: 'narrow',
        },
        {
          name: 'building_area',
          label: '建物面積',
          width: 'narrow',
        },
        {
          name: 'age',
          label: '建築年',
          width: 'narrow',
        },
        {
          name: 'structure',
          label: '構造',
          width: 'narrow',
        },
        {
          name: 'floor',
          label: '間取り',
          width: 'narrow',
        },
        {
          name: 'current_status',
          label: '現況',
          width: 'narrow',
        },
        {
          name: 'landmark',
          label: '地目',
          width: 'narrow',
        },
        {
          name: 'city_planning',
          label: '都市計画',
          width: 'narrow',
        },
        {
          name: 'use_area',
          label: '用途地域',
          width: 'narrow',
        },
        {
          name: 'building_ratio',
          label: '建ぺい率',
          width: 'narrow',
        },
        {
          name: 'floor_ratio',
          label: '容積率',
          width: 'narrow',
        },
        {
          name: 'facility',
          label: '設備',
          width: 'wide',
        },
        {
          name: 'remarks',
          label: '備考',
          width: 'wide',
        },
        {
          name: 'category',
          label: 'こだわり<br class="sp">条件',
          width: 'wide',
        },
      ],
      estateItems: [
        {
          name: 'label',
          label: '不動産<br class="sp">業者名',
        },
        {
          name: 'tel',
          label: '電話番号',
        },
      ],
    };
  },
  created() {
    this.getDetail();
  },
  watch: {
    $route() {
      this.getDetail();
    },
  },
  computed: {
    ...mapState(['layout', 'user', 'helper']),
    style() {
      return {
        '--status-label-color': `#${this.propertyStatus.color}`,
      };
    },
    address() {
      if (!this.property) return '';
      return `${this.property.city}${this.property.town}${this.property.address}${this.property.address2 || ''}`;
    },
    isLiked() {
      if (!this.property) return false;
      return this.user.likes.likeIds.includes(this.property.id);
    },
    landArea() {
      if (!this.property) return '-';
      let json;
      try {
        json = JSON.parse(this.property.land_area);
      } catch {
        return '-';
      }
      if (!json || (!json.meter && !json.tsubo)) return '-';
      return `${json.meter || '-'}㎡（${json.tsubo || 'm'}坪）`;
    },
    buildingArea() {
      if (!this.property) return '-';
      let json;
      try {
        json = JSON.parse(this.property.building_area);
      } catch {
        return '-';
      }
      if (!json) return '-';
      let str = '';
      let count = 0;
      json.forEach((obj, i) => {
        if (Number(obj.meter) && Number(obj.tsubo)) {
          if (count === 0) str += `${i + 1}F：${obj.meter || '-'}㎡（${obj.tsubo || '-'}坪）`;
          else str += `\n${i + 1}F：${obj.meter || '-'}㎡（${obj.tsubo || '-'}坪）`;
          count += 1;
        }
      });
      return str;
    },
    propertyStatus() {
      if (!this.helper.master.default?.labels || !this.property) return {};
      return this.helper.master.default.labels.propertyFlags.find((status) => status.value === this.property.flag);
    },
    categoryStr() {
      if (!this.property || !this.property.categories.length) return '-';
      let str = '';
      this.property.categories.forEach((ctg, i) => {
        if (i === 0) str = ctg.name;
        else str += `, ${ctg.name}`;
      });
      return str;
    },
  },
  methods: {
    getDetail() {
      const params = { number: this.$route.params.number };
      this.axios({
        method: 'get',
        url: '/v1/property/get/detail',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.property = res.detail;
          if (!this.property) {
            this.flag.notFound = true;
          } else {
            this.get('view');
            if (this.property.categories.length) this.get('categories');
            this.setMetas();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    get(type) {
      let params = {};
      if (type === 'view') params = { orderBy: 'view_count', order: 'desc', limit: 10 }; //
      else if (type === 'categories') params = { categories: this.property.categories.map((c) => c.id), categoriesSearchType: 'or', limit: 10 };
      else return;

      this.axios({
        method: 'get',
        url: '/v1/property/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.properties[type] = res.properties.data.filter((p) => p.id !== this.property.id);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    async changeLikeStatus() {
      this.flag.isLoading.like = true;
      await cf.updateLikes(this.property.id);
      this.$store.dispatch('user/likes/setLikes');
      this.flag.isLoading.like = false;
    },

    showViewer(idx) {
      this.viewerInitIdx = idx;
      this.flag.showViewer = true;
    },

    /** 個別物件のメタ情報仕込み */
    setMetas() {
      const meta = {};
      // 個別設定がない場合のデフォルト設定
      const defaults = {
        title: '桐生市空き家・空き地バンク',
        description: '群馬県桐生市の空き家・空き地バンクです。桐生市への移住定住をお考えの方、桐生市に空き家・空き地を所有している方からのご相談、ご利用をお待ちしております。',
        og_image: 'https://kiryu-akiya.s3.ap-northeast-1.amazonaws.com/production/ogp.png',
      };


      meta.title = `物件番号: ${this.property.number} | ${defaults.title}`;
      meta.description = `${this.property.label} | ${defaults.description}` || defaults.description;
      meta.og_image = this.property.medias.length ? this.property.medias[0].url : defaults.og_image;
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}`;

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的生成
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        // 存在する場合は書き換え
        canonical.href = meta.og_url;
      } else {
        // 存在しない場合は生成
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = meta.og_url;
        document.head.appendChild(linkTag);
      }
    },
  },
};
</script>

<style lang="scss" module="s">
.img_wrap {
  position: relative;
}

.status {
  &_wrap {
    position: absolute;
    top: 40px;
    left: 0;
  }
  padding: 8px 24px;
  background-color: var(--status-label-color);
  color: #fff;
  z-index: 10;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  &::after, &::before {
    content: '';
    border-width: 24px;
    border-style: solid;
    position: absolute;
    right: 0;
    transform: translateX(50%);
  }
  &::after {
    top: 0;
    border-color: var(--status-label-color) transparent transparent transparent;
  }
  &::before {
    bottom: 0;
    border-color: transparent transparent var(--status-label-color) transparent;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .typelabel {
    padding: 6px 16px;
    border-radius: 14px;
    background-color: var(--label-primary);
    color: var(--label-inverse-primary);
    font-weight: 700;
  }
}

.label {
  font-size: 24px;
  font-weight: 700;
}

.price {
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-right: 32px;

  &_wrap {
    display: flex;
    align-items: center;
  }

  &_label {
    font-size: 14px;
    opacity: .6;
  }

  &_separator {
    height: 24px;
    width: 1px;
    margin: 0 12px;
    background-color: var(--label-primary);
    opacity: .2;
  }
}

.fav {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  .star {
    width: 24px;
    height: 24px;
    background-image: url('/images/front_icons/star.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
    &.active {
      background-image: url('/images/front_icons/star_active.svg');
    }
  }
}

.bgbox {
  padding: 40px 80px;
  border-radius: 28px;
  background-color: var(--surface-field-gray);
}

.detail {
  > li {
    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  &_list {
    &.narrow {
      width: calc(100% / 3);
      @include smView {
        width: 100%;
      }
    }
    &.wide {
      width: 100%;
    }
  }
  &_title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
  &_item {
    display: flex;
    align-items: center;
  }
  &_label {
    width: 6em;
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    @include smView {
      width: 4em;
    }
  }
  &_value {
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid var(--label-primary);
    flex: 1;
    font-size: 14px;
    word-break: break-all;
  }
}
.floor {
  img {
    margin: 0 auto;
  }
}
.map {
  > iframe {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height: 340px;
    border-radius: 28px;
    border: 2px solid var(--border-point-gray);
    @include smView {
      height: 240px;
    }
  }
}

.movie_link {
  text-align: center;
  margin-top: 32px;
  text-decoration: underline;
  a {
    color: var(--label-primary);
  }
}

.section_title {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 48px;
  text-align: center;
}

.notFound {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@include smView {
  .pc {
    display: none;
  }
  .price {
    &_wrap {
      display: block;
    }
    margin-bottom: 16px;
  }

  .bgbox {
    padding: 24px;
  }

  .relations {
    padding: 0 20px;
  }
}
</style>
