<template>
  <div :class="$style.wrapper">
    <GlobalHeader />
    <router-view />
    <GlobalFooter />
    <BottomMenu />

    <LoadingsModal />
    <ContentsModal />
    <MessagesModal />
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex';
// import { throttle } from 'lodash';
import { mapState } from 'vuex';
import GlobalHeader from '@/components/layouts/GlobalHeader.vue';
import GlobalFooter from '@/components/layouts/GlobalFooter.vue';
import BottomMenu from '@/components/layouts/BottomMenu.vue';
// import { Spacer } from '@/components/parts';
import { ContentsModal, MessagesModal, LoadingsModal } from '@/components/modal';

export default {
  name: 'app',
  components: {
    // Spacer,
    GlobalHeader,
    GlobalFooter,
    BottomMenu,
    ContentsModal,
    MessagesModal,
    LoadingsModal,
  },
  data() {
    return {
      timeoutIds: {
        scroll: null,
        resize: null,
      },
    };
  },
  mounted() {
    this.checkSize();
    this.checkScroll();
    window.addEventListener('scroll', this.checkScroll);
    window.addEventListener('resize', this.checkSize);
    this.$store.dispatch('user/likes/setLikes');
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkScroll);
    window.removeEventListener('resize', this.checkSize);
  },
  computed: {
    ...mapState(['page']),
    isModal() {
      const contents = this.$store.state.modal.contents.modalName !== '';
      const loadings = this.$store.state.modal.loadings.modalName !== '';
      const messages = this.$store.state.modal.messages.modalName !== '';
      return contents || loadings || messages;
    },
  },
  created() {
    // ログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
    // this.setDisplaySize(window.innerWidth);
    this.innerWidth = window.innerWidth;
    // window.addEventListener('resize', throttle(() => this.setDisplaySize(window.innerWidth), 100));
  },
  watch: {
    $route(to) {
      // メタ情報の仕込み
      this.setMetas(to);
      // レイアウト用のstate更新
      this.checkScroll();
    },
  },
  methods: {
    setMetas: (route) => {
      const meta = {};
      // 個別設定がない場合のデフォルト設定
      const defaults = {
        title: '桐生市空き家・空き地バンク',
        description: '群馬県桐生市の空き家・空き地バンクです。桐生市への移住定住をお考えの方、桐生市に空き家・空き地を所有している方からのご相談、ご利用をお待ちしております。',
        og_image: 'https://kiryu-akiya.s3.ap-northeast-1.amazonaws.com/production/ogp.png',
      };

      meta.title = route.meta.title ? `${route.meta.title} | ${defaults.title}` : defaults.title;
      meta.description = route.meta.description || defaults.description;
      meta.og_image = route.meta.og_image || defaults.og_image;
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}`;
      meta.type = route.name === 'Home' ? 'website' : 'article';

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的削除
      const canonical = document.querySelector("link[rel='canonical']");
      // 存在する場合はリセットのため削除
      if (canonical) canonical.remove();
    },

    checkSize() {
      // スクロールを停止して500ms後に終了とする
      if (this.timeoutIds.resize) return;
      this.timeoutIds.resize = setTimeout(() => {
        this.timeoutIds.resize = 0;
        if (window) this.$store.dispatch('layout/setDisplaySize', window.innerWidth);
      }, 500);
    },
    checkScroll() {
      // スクロールを停止して500ms後に終了とする
      if (this.timeoutIds.scroll) return;
      this.timeoutIds.scroll = setTimeout(() => {
        this.timeoutIds.scroll = 0;
        this.scrolled();
      }, 500);
    },
    scrolled() {
      const triggerFirst = document.getElementById('trigger-first');
      const triggerSecond = document.getElementById('trigger-second');
      const triggerFirstTop = triggerFirst && triggerFirst.getBoundingClientRect().top < 0;
      const triggerSecondTop = triggerSecond && triggerSecond.getBoundingClientRect().top < 100;
      const isScrolledFirst = Boolean(triggerFirstTop);
      const isScrolledSecond = Boolean(triggerSecondTop);
      this.$store.dispatch('layout/setHeaderStatus', { first: isScrolledFirst, second: isScrolledSecond });
    },
  },
};
</script>

<style lang="scss">
/**
 * ここでimportしているscssは
 * 全てのページに適用されます
 */
@import './assets/scss/_variables.scss';
@import './assets/scss/_keyframes.scss';
@import './assets/scss/_mq.scss';
@import './assets/scss/_fonts.scss';
@import './assets/scss/_reset.scss';
@import './assets/scss/modal.scss';
@import './assets/scss/style.scss';
</style>

<style lang="scss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.contents {
  flex-shrink: 1;
  flex-grow: 10;
  padding: 0 var(--g);
}

@media print {
  .wrapper {
    display: block;
  }
}
</style>
