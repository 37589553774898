import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home/index.vue';

/** @Property */
import PropertyDetail from '@/views/Property/Detail.vue';
import PropertyPrint from '@/views/Property/Print.vue';

/** @Pages */

/** @Account */

/** @Edits */

/** @Error */
import NotFound from '@/views/Error/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },

  /** properties */
  {
    path: '/bukken/:number',
    name: 'PropertyDetail',
    component: PropertyDetail,
  },
  {
    path: '/bukken/print/:number',
    name: 'PropertyPrint',
    component: PropertyPrint,
  },

  /** @Error */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'お探しのページは見つかりませんでした',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to, from, savedPosition) {
    let position = {};
    // fromとtoの違いがない場合はスルー
    if (from.path && to.path === from.path) return;
    if (!to.hash) {
      // ハッシュがなく、
      if (savedPosition) { // ブラウザバック
        position = { top: savedPosition.top };
      } else { // ページ遷移
        position = { top: 0 };
      }
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
