<template>
  <transition name="fade" mode="out-in">
    <div class="modal contents" v-if="isShow">
      <div class="modal__bg" v-on:click="hideModal" :class="$style.wrap"></div>

      <div class="modal__content" :class="$style.modal">
        <!-- modalNameに応じて表示するコンポーネントを変化 -->
        <component
          v-bind:is="contents.modalName"
          v-bind:data="contents.data" />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
// モーダルコンポーネントの登録

export default {
  name: 'ContentsModal',
  components: {
  },
  computed: {
    ...mapState('modal', ['contents']),
    isShow() {
      return this.contents.modalName !== '';
    },
  },
  methods: {
    hideModal(args = null) {
      if (this.contents.modalName !== 'regist-username') { // 氏名登録はキャンセル不可
        this.$store.dispatch('modal/contents/hideModal', args, { root: true });
      }
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  z-index: 910;
}
.modal {
  position: relative;
  z-index: 920;
  padding: 40px;
  width: 80%;
  max-height: 70%;
  background-color: #fff;
  border-radius: 10px;
  overflow: scroll;
  @include smView {
    padding: 20px;
    max-height: calc(70% - 69px);
    // top: 50%;
  }
}
</style>
