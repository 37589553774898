<template>
  <header
    v-if="!layout.sizeChecked && isShowPage"
    :class="[
      s.wrap,
      s[layout.header.color],
      layout.header.color === 'transparent' || flag.showLinks ? '' : s.border,
      flag.showLinks ? s.shadow : ''
    ]">
    <div :class="s.base">
      <transition name="fade">
        <div
          :class="[
            s.btn_area,
            !['lg', 'md'].includes(layout.displaySize) ? s.wide : '',
          ]"
          v-if="['lg', 'md'].includes(layout.displaySize) || !layout.header.isShowTitle">
          <FlexList
            :spaceX="12"
            :spaceY="11"
            :count="!['lg', 'md'].includes(layout.displaySize) ? 1 : null"
            :wrap="true">
            <li
              v-for="menu in menuItems"
              :key="menu.name">
              <basic-btn
                :type="'bdr'"
                :color="['lg', 'md'].includes(layout.displaySize) ? 'gry' : 'wht-blk'"
                :size="'sm'"
                :width="'fit-content'"
                :tag="'a'"
                :link="menu.name.includes('iju') ? `${ijuBaseUrls[helper.env.name]}${menu.link}` : menu.link"
                :openOtherTab="true"
                :class="['lg', 'md'].includes(layout.displaySize) ? '' : s.btn">{{ menu.label }}</basic-btn>
            </li>
          </FlexList>
        </div>
      </transition>
      <div
        v-if="layout.header.isShowTitle">
        <div :class="s.title">
          <div class="pc">
            <router-link to="/">桐生市空き家・空き地バンク</router-link>
          </div>
          <div class="sp">
            <div><router-link to="/">桐生市空き家・空き地バンク</router-link></div>
            <div
              @click="flag.showLinks = true"
              :class="s.trigger"><font-awesome-icon :class="s.down" :icon="['fas', 'caret-down']"/>桐生市のほかサイトを見る</div>
          </div>
        </div>
        <div v-if="flag.showLinks" :class="s.links_wrap">
          <ul :class="s.links">
            <li v-for="menu in menuItems"
            :key="menu.name">
              <basic-btn
                @click="flag.showLinks = false"
                :type="'bdr'"
                :color="'gry'"
                :size="'sm'"
                :width="'fit-content'"
                :tag="'a'"
                :link="menu.name.includes('iju') ? `${ijuBaseUrls[helper.env.name]}${menu.link}` : menu.link"
                :openOtherTab="true">{{ menu.label }}</basic-btn>
            </li>
          </ul>
          <div :class="s.close" @click="flag.showLinks = false">
            <i :class="s.xmark" class="fa-solid fa-xmark" />とじる
          </div>
        </div>
      </div>
    </div>
    <div
      :class="s.bg"
      v-if="flag.showLinks"
      @click.self="flag.showLinks = false"/>
  </header>
</template>

<script>
import { mapState } from 'vuex';
import {
  FlexList,
  BasicBtn,
} from '../parts';

export default {
  name: 'global-header',
  components: {
    FlexList,
    BasicBtn,
  },
  data() {
    return {
      flag: {
        showLinks: false,
      },
      ijuBaseUrls: {
        production: 'https://kiryu-iju.jp',
        develop: 'https://dev.kiryu-iju.jp',
        local: 'http://localhost:3088',
      },
      menuItems: [
        {
          name: 'iju-kiryu-area',
          label: '桐生市各エリア紹介へ',
          link: '/about/#about',
        },
        {
          name: 'akiya-old',
          label: '桐生市ホームページへ（空き家・空き地バンク）',
          link: 'https://www.city.kiryu.lg.jp/shisei/machi/iinekiryu/akiya/index.html',
        },
      ],
    };
  },
  computed: {
    ...mapState(['layout', 'helper']),
    showTitle() {
      return this.layout.header.isShowTitle;
    },
    isShowPage() {
      return !this.$route.path.includes('print');
    },
  },
  watch: {
    showTitle() {
      if (!this.layout.header.showTitle) this.flag.showLinks = false;
    },
  },
  methods: {
    logout() {
      // const userState = useUserStore()
      // userState.login.logout()
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: fixed;
  font-size: 12px;
  width: 100%;
  z-index: 100;
  transition: all .3s;
  height: fit-content;

  &.shadow {
    height: 100%;
  }

  &.border {
    border-bottom: 1px solid var(--border-primary);
  }

  &.semi-transparent-white {
    .base {
      background-color: rgba(#ffffff, .9);
    }
  }

  &.transparent {
    .base {
      background-color: transparent;
    }
  }
}

.base {
  background-color: var(--surface-white);
  position: relative;
  height: var(--header-height);
  z-index: 2;
}

.btn_area {
  max-width: calc(50% - 170px);
  padding-left: 24px;
  height: var(--header-height);
  display: flex;
  align-items: center;
  &.wide {
    margin-top: 12px;
    width: 100%;
    max-width: 100%;
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 700;
  a {
    color: var(--label-primary);
  }
}

@include smView {
  .wrap {
    font-size: 16px;
    color: var(--brand-gray);
    &.shadow {
      border-bottom: none;
    }
  }
  .base {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 16px;
    text-align: center;
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    a {
      color: var(--brand-gray);
    }
  }
  .trigger {
    display: inline-block;
    margin: 7px auto 0;
    font-size: 11px;
    font-weight: 700;
    text-align: center;
    .down {
      margin-right: 6px;
    }
  }
  .links {
    &_wrap {
      position: absolute;
      top: 100%; left: 0;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding: 8px 0 24px;
    }
    display: flex;
    flex-flow: column;
    align-items: center;
    > li {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  .close {
    margin-top: 16px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    .xmark {
      display: inline-block;
      font-size: 20px;
      margin-right: 6px;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000000, .2);
  }
}

</style>
