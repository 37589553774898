/**
* お気に入り管理
*/

import cf from '@/mixins/commonFunctions.js';

const actions = {
  /** state.isLoginの変更 */
  setLikes({ commit }) {
    const ls = cf.getLocalStorage('akiya');
    if (ls && ls.likes) commit('setLikeState', ls.likes);
    else commit('setLikeState', []);
  },
};

const mutations = {
  /** チェック済みステート */
  setLikeState(state, likeIds) {
    state.likeIds = likeIds;
  },
};

const state = {
  likeIds: [],
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
