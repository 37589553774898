import moment from 'moment';
import axios from '@/plugins/axios';

export default {
  /**
   * LocalStorage 関連
   * windowを使っているのでcsr処理になるようにする必要がある
   */
  saveLocalStorage(obj, key) {
    if (!key) return;
    const ls = this.getLocalStorage(key);
    const save = ls ? { ...ls, ...obj } : obj;
    const stringify = JSON.stringify(save);
    window.localStorage.setItem(key, stringify);
  },
  getLocalStorage(key) {
    let result;
    const ls = String(window.localStorage.getItem(key));
    if (ls) result = JSON.parse(ls);
    return result;
  },
  deleteLocalStorage(key, key2 = null) {
    if (!key) return;
    const ls = this.getLocalStorage(key);
    if (ls) {
      if (!key2) {
        window.localStorage.removeItem(key);
        console.log(`local storage [${key}] を削除しました`);
      } else {
        // 指定キー以外を一時ストック
        const stock = {};
        const keys = Object.keys(ls);
        for (let i = 0; i < keys.length; i += 1) {
          const k = keys[i];
          if (k !== key2) stock[k] = ls[k];
        }
        window.localStorage.removeItem(key);
        this.saveLocalStorage(stock, key);
        console.log(`local storage [${key}] の [${key2}] を削除しました`);
      }
    } else {
      console.log(`local storage [${key}] は 存在しません`);
    }
  },


  /**
   * property count関連
   */
  async changePropertyCount(params) {
    await axios({
      method: 'POST',
      url: '/v1/property/count/update',
      params,
    })
      .then(() => {})
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  async addViewCount(id) {
    const params = {
      id,
      column: 'view_count',
      type: 1,
    };
    await this.changePropertyCount(params);
  },

  async updateLikes(id) {
    const ls = this.getLocalStorage('akiya');
    let likes = ls?.likes || [];
    const type = likes.includes(id) ? 0 : 1;

    // likesに追加 or likesから削除
    if (!type) likes = likes.filter((l) => l !== id);
    else likes.push(id);

    // localStorageに保存
    this.saveLocalStorage({ likes }, 'akiya');

    // db更新
    await this.changePropertyCount({
      id,
      column: 'like_count',
      type,
    });
  },

  /**
   * ページ閲覧権限
   * userStoreを受け取って閲覧権限があるか判定
   */
  async checkAuth(user, role) {
    if (!user || !user.login.check) return;
    if (!user.login.isLogin || (user.role && user.role.role !== role)) {
      // loginへリダイレクト
      alert('ページを閲覧する権限がありません。\nログインしなおしてください。');
      this.router().push('/login/');
    }
  },

  methods: {
    /**
     * サムネイル取得
     * is_primaryがあればそのオブジェクト、
     * なかったら0番めのオブジェクト、
     * mediasがなかったらnullを返却
     */
    getThumb(medias) {
      const isPrimary = medias.find((m) => m.isPrimary === 1);
      return isPrimary || medias[0] || { id: null, url: '/images/sample.png' };
    },


    /** 桐生市空き家・空き地バンクタンプのフォーマット */
    formatTimestamp(stamp, format = 'YYYY-MM-DD') {
      let result = moment(stamp, 'X').format(format);
      if (!String(stamp).includes('GMT')) {
        result = moment(stamp).format(format);
      }
      return result;
    },
  },
};
