<template>
  <div v-if="property" :style="style">
    <div :class="s.wrap">
      <div>
        <PropertyDetailTopImg
          v-if="property.medias.length"
          :medias="property.medias"
          :showMore="false"/>
        <spacer :y="1"/>
        <div :class="[s.flex, s.sb]">
          <div :class="s.flex">
            <div :class="s.labels">
              <div :class="s.typelabel">{{ property.propTypes[0].name }}</div>
            </div>
            <spacer :x="1"/>
            <div :class="s.label">{{ property.label }}</div>
          </div>
          <div :class="s.price_wrap">
            <div :class="s.price">
              <span :class="s.price_label">{{ property.dealTypes[0].is_rent ? '賃料（月額）' : '売却希望価格' }}</span>
              <span :class="s.price_separator"/>
              <span v-if="property.price && !property.is_negotiated">&yen;{{ property.price.toLocaleString() }}</span>
              <span v-else>応相談</span>
            </div>
          </div>
        </div>
        <spacer :y="1"/>
        <div>
          <FlexList
            :spaceX="8"
            :spaceY="8"
            :wrap="true">
            <li
              v-for="item in items"
              :key="item.name"
              :class="[s.detail_list, item.width === 'narrow' ? s.narrow : s.wide]">
              <div :class="s.detail_item">
                <div :class="[s.detail_label, item.labelWidth === 'narrow' ? s.narrow_label : '']" v-html="item.label"/>
                <div :class="s.detail_value" class="n2br">
                  <span v-if="item.name === 'land_area'">{{ landArea }}</span>
                  <span v-else-if="item.name === 'building_area'" v-html="buildingArea"/>
                  <span v-else-if="item.name === 'address'">{{ address }}</span>
                  <span v-else-if="item.name === 'category'">{{ categoryStr }}</span>
                  <span v-else-if="item.name === 'remarks'">{{ remarksStr }}</span>
                  <span v-else class="n2br" v-html="property[item.name] || '-'"/>
                </div>
              </div>
            </li>
          </FlexList>
          <spacer :y="1"/>
          <FlexList
            v-if="property.estates.length"
            :spaceX="80"
            :spaceY="8"
            :count="2"
            :wrap="true">
            <li v-for="item in estateItems" :key="item.name">
              <div :class="s.detail_item">
                <div :class="s.detail_label" class="n2br" v-html="item.label"/>
                <div :class="s.detail_value">{{ property.estates[0][item.name] || '-' }}</div>
              </div>
            </li>
          </FlexList>
        </div>
      </div>
    </div>
    <div :class="[s.wrap, s.break_before]" v-if="property.floors.length">
      <div :class="s.floors_label">物件番号：{{ property.number }}　{{ property.label }}　間取り図</div>
      <ul :class="s.floors">
        <li v-for="floor in property.floors" :key="floor.id">
          <img :src="floor.url" alt="">
        </li>
      </ul>
    </div>
    <div
      :id="addMapMarkType === 'id' ? 'map' : ''"
      :class="[
        addMapMarkType === 'class' ? 'print-map' : '',
        s.wrap, s.break_before
      ]"
      v-if="property.longitude && property.latitude">
      <div :class="s.map">
        <iframe
          :src="`//maps.google.co.jp/maps?ll=${property.latitude},${property.longitude}&amp;q=${property.latitude},${property.longitude}&amp;output=embed&amp;t=m&amp;z=17`"
          frameborder="0"
          scrolling="no"/>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PropertyDetailTopImg,
  Spacer,
  FlexList,
} from '@/components/parts';

export default {
  name: 'property-print',
  components: {
    PropertyDetailTopImg,
    Spacer,
    FlexList,
  },
  props: {
    property: {
      type: Object,
    },
    addMapMarkType: {
      type: String,
      default: 'none',
      validator: (value) => ['id', 'class', 'none'].includes(value),
    },
  },
  data() {
    return {
      flag: {
        isCity: false,
      },
      items: [
        {
          name: 'number',
          label: '物件番号',
          width: 'wide',
        },
        {
          name: 'address',
          label: '所在地',
          width: 'wide',
        },
        {
          name: 'station',
          label: '最寄り駅',
          width: 'wide',
        },
        {
          name: 'bus_station',
          label: '最寄りバス停',
          width: 'wide',
        },
        {
          name: 'land_area',
          label: '土地面積',
          width: 'narrow',
        },
        {
          name: 'building_area',
          label: '建物面積',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'age',
          label: '建築年',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'structure',
          label: '構造',
          width: 'narrow',
        },
        {
          name: 'floor',
          label: '間取り',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'current_status',
          label: '現況',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'landmark',
          label: '地目',
          width: 'narrow',
        },
        {
          name: 'city_planning',
          label: '都市計画',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'use_area',
          label: '用途地域',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'building_ratio',
          label: '建ぺい率',
          width: 'narrow',
        },
        {
          name: 'floor_ratio',
          label: '容積率',
          width: 'narrow',
          labelWidth: 'narrow',
        },
        {
          name: 'facility',
          label: '設備',
          width: 'wide',
        },
        {
          name: 'remarks',
          label: '備考',
          width: 'wide',
        },
        {
          name: 'category',
          label: 'こだわり条件',
          width: 'wide',
        },
      ],
      estateItems: [
        {
          name: 'label',
          label: '不動産業者名',
        },
        {
          name: 'tel',
          label: '電話番号',
        },
      ],
    };
  },
  created() {
    const userAgent = navigator.userAgent;
    this.flag.isCity = userAgent.includes('X11; Linux x86_64');
  },
  computed: {
    address() {
      if (!this.property) return '';
      return `${this.property.city}${this.property.town}${this.property.address}${this.property.address2 || ''}`;
    },
    landArea() {
      if (!this.property) return '-';
      let json;
      try {
        json = JSON.parse(this.property.land_area);
      } catch {
        return '-';
      }
      if (!json || (!json.meter && !json.tsubo)) return '-';
      return `${json.meter || '-'}㎡（${json.tsubo || 'm'}坪）`;
    },
    buildingArea() {
      if (!this.property) return '-';
      let json;
      try {
        json = JSON.parse(this.property.building_area);
      } catch {
        return '-';
      }
      if (!json) return '-';
      let str = '';
      let count = 0;
      json.forEach((obj, i) => {
        if (Number(obj.meter) && Number(obj.tsubo)) {
          if (count === 0) str += `${i + 1}F：${obj.meter || '-'}㎡（${obj.tsubo || '-'}坪）`;
          else str += `\n${i + 1}F：${obj.meter || '-'}㎡（${obj.tsubo || '-'}坪）`;
          count += 1;
        }
      });
      return str;
    },
    categoryStr() {
      if (!this.property || !this.property.categories.length) return '-';
      let str = '';
      this.property.categories.forEach((ctg, i) => {
        if (i === 0) str = ctg.name;
        else str += `, ${ctg.name}`;
      });
      return str;
    },
    remarksStr() {
      if (!this.property || !this.property.remarks) return '-';
      let str = '';
      const splited = this.property.remarks.split('\n');
      splited.forEach((word, i) => {
        if (i !== 0) str += ` / ${word}`;
        else str += word;
      });
      return str;
    },
    style() {
      const w = this.flag.isCity ? 600 : 720;
      return `--width: ${w}px;`;
    },
  },
};
</script>

<style lang="scss" module="s">
$w: var(--width);
$h: calc($w * (700 / 496));

.wrap {
  width: $w;
  // height: $h;
  &.break_before {
    break-before: page;
  }
}

.flex {
  display: flex;
  align-items: center;
  &.sb {
    justify-content: space-between;
  }
}

.labels {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .typelabel {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid var(--label-primary);
    font-weight: 700;
  }
}

.label {
  font-size: 16px;
  font-weight: 700;
}

.price {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 32px;

  &_wrap {
    display: flex;
    align-items: center;
  }

  &_label {
    font-size: 12px;
    opacity: .6;
  }

  &_separator {
    height: 24px;
    width: 1px;
    margin: 0 8px;
    background-color: var(--label-primary);
    opacity: .2;
  }
}

.detail {
  > li {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  &_list {
    &.narrow {
      width: calc(100% / 3);
    }
    &.wide {
      width: 100%;
    }
  }
  &_item {
    display: flex;
    align-items: stretch;
  }
  &_label {
    width: 6em;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    &.narrow_label {
      width: 4em;
    }
  }
  &_value {
    display: flex;
    align-items: center;
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid var(--label-primary);
    flex: 1;
    font-size: 12px;
    word-break: break-all;
  }
}

.map {
  > iframe {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    height: $w;
    border-radius: 28px;
    border: 2px solid var(--border-point-gray);
  }
}

.floors {
  display: flex;
  align-items: center;
  flex-direction: column;
  &_label {
    font-size: 14px;
    text-align: center;
  }
  > li {
    height: calc(($h - 60px) / 3);
    margin-top: 8px;
    img {
      height: 100%;
    }
  }
}
</style>
