<template>
  <div :class="s.wrap">
    <div :class="s.contents">
      <div :class="s.bg" @click="close"/>
      <div :class="s.img_wrap" @click="close">
        <div :class="[s.img]">
          <img @click.stop="" :src="showMedia.url" alt="">
          <div
            :class="[s.angle, s.icon, s.left]"
            v-on:click.stop="changeIdx('back')"><i class="fa-solid fa-angle-left"/></div>
          <div
            :class="[s.angle, s.icon, s.right]"
            v-on:click.stop="changeIdx('next')"><i class="fa-solid fa-angle-right"/></div>
        </div>
        <!-- キャプション -->
        <div :class="s.caption" v-if="showMedia.caption">{{ showMedia.caption }}</div>
        <!-- ページ移動 -->
      </div>

      <!-- 閉じる -->
      <div
        :class="[s.cross, s.icon]"
        v-on:click="close"><i class="fa-solid fa-xmark"/></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // medias
    //  * url：画像パス（必須）
    medias: {
      type: Array,
      required: true,
    },
    // showIdx
    //  * 表示する画像のidx
    initIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      flag: {
      },
      showIdx: 0,
      caption: '道路側から見た様子（赤い部分が該当の土地）',
    };
  },
  created() {
    if (this.initIdx) this.showIdx = this.initIdx;
  },
  computed: {
    showMedia() {
      return this.medias[this.showIdx];
    },
  },
  methods: {
    changeIdx(type) {
      if (type === 'next') {
        this.showIdx = this.showIdx < this.medias.length - 1 ? this.showIdx + 1 : 0;
      } else if (type === 'back') {
        this.showIdx = this.showIdx < 1 ? this.medias.length - 1 : this.showIdx - 1;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module="s">
.wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  z-index: 200;
}

.contents {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(87, 87, 87, 0.88);
}

.img {
  $x-pdg: 100px;
  $y-pdg: 150px;
  position: relative;
  img {
    display: block;
    max-width: calc(100vw - $x-pdg * 2);
    max-height: calc(100vh - $y-pdg * 2);
    position: relative;
  }

  @include smView {
    $x-pdg: 50px;
    $y-pdg: 100px;
    position: relative;
    img {
      display: block;
      max-width: calc(100vw - $x-pdg * 2);
      max-height: calc(100vh - $y-pdg * 2);
      position: relative;
    }
  }
}

.caption {
  position: absolute;
  display: inline-block;
  left: 50%;
  bottom: -40px;
  padding: 4px 12px;
  transform: translateX(-50%);
  color: var(--label-inverse-primary);
  text-align: center;
}

.icon {
  background-color: rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  cursor: pointer;
  color: var(--label-inverse-primary);
}

.angle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.left {
    left: -50px;
  }

  &.right {
    right: -50px;
  }
}

.cross {
  position: absolute;
  top: 45px;
  right: 45px;
  border-radius: 50%;
}
</style>
