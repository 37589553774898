<template>
  <div :class="s.loaderWrapper" :style="style">
    <slot />
    <div :class="[s.loaderSimple, mini ? s.mini : '']"/>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 300,
    },
    mini: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        '--height': `${this.height}px`,
      };
    },
  },
};
</script>

<style lang="scss" module="s">
.loaderSimple {
  width: 2em;
  height: 2em;
  animation: rotation 1s infinite linear;
  border: 1px solid rgba(black, 0.2);
  border-top-color: rgba(black, 0.7);
  border-radius: 50%;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  margin: auto;
  z-index: 1;
  &.mini {
    width: 1.2em;
    height: 1.2em;
  }
}

.loaderWrapper {
  position: relative;
  height: var(--height);
}


@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
</style>
