<template>
  <div>
    <div
      :class="s.notFound"
      v-if="flag.notFound">
      <spacer :y="10"/>
      <div>無効なURLです。</div>
      <spacer :y="4"/>
      <BasicBtn
        tag="a"
        :link="'/'">トップページへ</BasicBtn>
    </div>
    <ul>
      <li v-for="property in properties" :key="property.id">
        <PrintPage
          v-if="property"
          :property="property"
          :addMapMarkType="$route.params.number !== 'likes' ? 'id' : 'class'"/>
      </li>
    </ul>

    <!-- loading -->
    <div v-if="!flag.isVisible" :class="s.wrapper">
      <div :class="s.loader"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import {
  PrintPage,
  BasicBtn,
  Spacer,
} from '@/components/parts';

export default {
  name: 'property-print',
  components: {
    PrintPage,
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      flag: {
        notFound: false,
        isVisible: false,
      },
      properties: [],
      items: [
        { name: 'price', label: '価格' },
        { name: 'address', label: '所在地' },
        { name: 'station', label: '最寄り駅' },
        { name: 'bus_station', label: '最寄りバス停' },
        { name: 'land_area', label: '土地面積' },
        { name: 'building_area', label: '建物面積' },
        { name: 'age', label: '建築年' },
        { name: 'structure', label: '構造' },
        { name: 'floor', label: '間取り' },
        { name: 'current_status', label: '現況' },
        { name: 'landmark', label: '地目' },
        { name: 'city_planning', label: '都市計画' },
        { name: 'use_area', label: '用途地域' },
        { name: 'building_ratio', label: '建ぺい率' },
        { name: 'floor_ratio', label: '容積率' },
        { name: 'facility', label: '設備' },
        { name: 'remarks', label: '備考' },
        { name: 'category', label: 'こだわり条件' },
      ],
    };
  },
  mounted() {
    this.get();
  },
  computed: {
    ...mapState(['user']),
    address() {
      if (!this.property) return '';
      return `${this.property.city}${this.property.town}${this.property.address}${this.property.address2 || ''}`;
    },
  },
  methods: {
    get() {
      const pathParams = this.$route.params.number;
      const params = {
        withCategory: 1,
      };
      if (pathParams === 'likes') {
        params.ids = this.user.likes.likeIds || [];
      } else {
        params.number = this.$route.params.number;
      }

      this.axios({
        methods: 'GET',
        url: '/v1/property/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.properties = res.properties.data;
          if (!this.properties.length) {
            this.flag.notFound = true;
            return;
          }
          // 画像が描画されるのに時間がかかりそうなのでsetTimeoutでタイミングずらす
          this.$nextTick(() => {
            if (pathParams === 'likes') {
              const interval = 1000;
              const mapElems = document.getElementsByClassName('print-map');
              const length = mapElems.length;
              if (length) {
                // setTimeoutの時間をずらして徐々に下にスクロールするようにする
                for (let i = 0; i < length; i += 1) {
                  setTimeout(() => {
                    mapElems[i].scrollIntoView();
                  }, interval * (i + 1));
                }
                // 可視化
                setTimeout(() => {
                  this.flag.isVisible = true;
                }, interval * (length + 1));
                // プリントダイアログ表示
                setTimeout(() => {
                  window.print();
                }, interval * (length + 1) + interval);
              }
            } else {
              const mapElem = document.getElementById('map');
              if (mapElem) mapElem.scrollIntoView();
              this.flag.isVisible = true;
              setTimeout(() => {
                window.print();
              }, 1500);
            }
          });
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.notFound {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
